/* Rotating logo */

.ratating-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .ratating-logo {
    animation: ratating-logo-spin infinite 20s linear;
  }
}

@keyframes ratating-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
